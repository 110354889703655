import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import AdminLogin from "./AdminLogin";
import AdminAuth from "./AdminAuth";
import CustomerManagement from "./AdminDashboard/CustomerManagement";
import StockManagement from "./AdminDashboard/StockManagement";
import SuperUser from "./AdminDashboard/SuperUser";
import BrokerContact from "./AdminDashboard/BrokerContact";
import CustomerActivation from "./AdminDashboard/CustomerActivation";
import DisplayForm from "./AdminDashboard/DisplayForm";
import Menu from "../Account/Menu";
import "../../css/AdminManagement/AdminTable.css";
import "../../css/AdminManagement/CustomerManagement.css";

import {
  Dashboard as DashboardIcon,
  BoxSearch,
  SecurityUser,
  HomeHashtag,
  ProfileUser,
} from "../CustomComponents/SwiftIcon/Icons";

import AdminHeader from "./AdminHeader";
import ImageShow from "./AdminDashboard/ImageShow";
import BrokerDashbaord from "./AdminDashboard/BrokerDashbaord";
import DealerManagement from "./AdminDashboard/DealerManagement";
import MFManagement from "./AdminDashboard/MFManagement";

const menu_array = [
  {
    title: "",
    options: [
      {
        name: "Dashboard",
        title: "Dashboard",
        path: "dashboard",
        icon: DashboardIcon,
        component: AdminDashboard,
      },
      {
        name: "Customer Management",
        title: "Customer Management",
        path: "customer-management",
        icon: ProfileUser,
        component: CustomerManagement,
      },
      {
        name: "Broker Contact",
        title: "Broker Contact",
        path: "broker",
        icon: HomeHashtag,
        component: BrokerContact,
      },
      {
        name: "Super User Management",
        title: "Super User Management",
        path: "superuser",
        icon: SecurityUser,
        component: SuperUser,
      },
      {
        name: "Stock Management",
        title: "Stock Management",
        path: "stock",
        icon: BoxSearch,
        component: StockManagement,
      },
      {
        name: "Customer Activation",
        title: "Customer Activation",
        path: "customer-activation",
        icon: ProfileUser,
        component: CustomerActivation,
      },
      {
        name: "Dealer Management",
        title: "Dealer Management",
        path: "dealer-management",
        icon: ProfileUser,
        component: DealerManagement,
      },
      {
        name: "MF Management",
        title: "MF Management",
        path: "mf-management",
        icon: ProfileUser,
        component: MFManagement,
      },
    ],
  },
];

function AdminManagement() {
  const location = useLocation();

  const isLoginForm = location.pathname.includes("/login");
  const isImagePage = location.pathname.includes("/image/");

  return (
    <div className={!isLoginForm && !isImagePage ? "swift-admin-wrapper" : ""}>
      {!isLoginForm && !isImagePage && <Menu menu_array={menu_array} />}

      <div className={!isLoginForm && !isImagePage ? "swift-admin-body" : ""}>
        {!isLoginForm && !isImagePage && (
          <AdminAuth>
            <AdminHeader menu_array={menu_array} />
          </AdminAuth>
        )}
        <div className="swift-admin-body-wrapper">
          <Routes>
            <Route path="*" element={<Navigate to="/admin/login" />} />
            <Route path="/login" element={<AdminLogin />} />
            <Route
              path="/dashboard"
              element={
                <AdminAuth>
                  <AdminDashboard />
                </AdminAuth>
              }
            />

            <Route
              path="/customer-management"
              element={
                <AdminAuth>
                  <CustomerManagement />
                </AdminAuth>
              }
            />
            <Route
              path="/stock"
              element={
                <AdminAuth>
                  <StockManagement />
                </AdminAuth>
              }
            />
            <Route
              path="/superuser"
              element={
                <AdminAuth>
                  <SuperUser />
                </AdminAuth>
              }
            />
            <Route
              path="/broker/*"
              element={
                <AdminAuth>
                  <BrokerDashbaord />
                </AdminAuth>
              }
            />
            {/* <Route path="/broker" element={<AdminAuth><BrokerContact /></AdminAuth>} /> */}
            <Route
              path="/customer-activation"
              element={
                <AdminAuth>
                  <CustomerActivation />
                </AdminAuth>
              }
            />
            <Route
              path="/form/:pan"
              element={
                <AdminAuth>
                  <DisplayForm />
                </AdminAuth>
              }
            />
            <Route path="/image/:url" element={<ImageShow />} />
            <Route
              path="/dealer-management/*"
              element={
                <AdminAuth>
                  <DealerManagement />
                </AdminAuth>
              }
            />
            <Route
              path="/mf-management/*"
              element={
                <AdminAuth>
                  <MFManagement />
                </AdminAuth>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminManagement;
