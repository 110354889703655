import React, { useState, useRef, useEffect } from "react";
import {
  ArrowRight2,
  Cross,
  UserSearch,
} from "../../CustomComponents/SwiftIcon/Icons";
import ServerRequest from "../../../exports/ServerRequest";
import { GetCookie } from "../../../exports/CookieManagement";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import { COLOR_VARS } from "../../../exports/ColorVars";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";
import axios from "axios";
import Pulse from "../../Loader/Pulse";

function MFManagement() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    // fileInputRef.current.click();
    setOpen(true);
  };

  return (
    <div className="swift-account-main swift-mf-management">
      <button id="upload-btn" onClick={handleClick}>
        Upload BSE Master File
      </button>
      <p id="note">Note: File name should start with "SCHMSTRPHY"</p>
      {open && <UploadModal closeModal={setOpen} />}
      {/* <MFBSETable /> */}
    </div>
  );
}

function UploadModal({ closeModal }) {
  const token = GetCookie("admin_token");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const FileHandler = async (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const SubmitFile = async () => {
    if (!file) {
      DisplayAlert("Warning", "Please select a file to upload");
      return;
    }

    const name = file?.name || "";

    if (!name?.includes("SCHMSTRPHY")) {
      DisplayAlert("Warning", "File name must start with SCHMSTRPHY");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios({
        url: `/admin/mf/bse-upload?token=${token}`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.data?.error) {
        DisplayAlert("Warning", response?.data?.error);
      } else {
        DisplayAlert("Success", "File uploaded successfully");
        handleClose();
      }
    } catch (error) {
      const message = error?.message || "Something went wrong";
      DisplayAlert("Warning", message);
    } finally {
      setLoading(false);
    }
  };

  const DisplayAlert = (heading = "", msg = "") => {
    Alert({
      TitleText: heading,
      Message: msg,
      BandColor: "#E51A4B",
      BoxColor: "#ffffff",
      TextColor: "#000000",

      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#e51a4b",
        Time: 3,
      },
    });
  };

  const handleClose = () => {
    closeModal();
    setFile(null);
    fileInputRef.current = null;
  };

  return (
    <SwiftModal closeModal={handleClose} top="2%">
      <div className="swift-account-form">
        <>
          {loading && (
            <div className="swift-account-form-loader">
              <Pulse />
            </div>
          )}
          <div className="swift-account-form-header">
            <div className="swift-account-form-title">BSE Master File</div>
            <div className="swift-account-svg" onClick={handleClose}>
              <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
            </div>
          </div>
          <div className="swift-account-form-body">
            <div className="swift-account-form-body-content mf-file-upload-wrapper">
              <button id="select-btn" onClick={handleClick}>
                Select BSE Master File
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".txt"
                onChange={FileHandler}
              />
              <p id="file-name">{file && file?.name}</p>
            </div>
            <div className="login-footer">
              <div className="swift-button-icon" onClick={SubmitFile}>
                <div className="button-icon">
                  <ArrowRight2 size={20} />
                </div>
                <p>Upload File</p>
              </div>
            </div>
          </div>
        </>
      </div>
    </SwiftModal>
  );
}

function MFBSETable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [config, setConfig] = useState({
    search: "",
    limit: 20,
    skip: 0,
  });

  const token = GetCookie("admin_token");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const query = `limit=${config?.limit}&skip=${config?.skip}&search=${config?.search}`;
      const data = await ServerRequest({
        url: `/admin/mf/bse?token=${token}&${query}`,
        method: "get",
      });
      setData(data?.data || []);
      setLoading(false);
    };
    fetchData();
  }, [config]);

  return (
    <div className="admin-table-wrapper">
      {loading && <></>}
      {(!data || !Array.isArray(data) || data?.length === 0) && <>NO DATA</>}
      <div className="admin-table-data admin-wrapper">
        <div className="admin-history-container">
          <table>
            <thead>
              <tr>
                <td>Scheme Code</td>
                <td>ISIN</td>
                <td>Scheme Type</td>
                <td colSpan={3}>Scheme Name</td>
                <td>Start Date</td>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.Scheme_Code || "-"}</td>
                    <td>{item?.ISIN || "-"}</td>
                    <td>{item?.Scheme_Type || "-"}</td>
                    <td colSpan={3} className="scheme-name">
                      {item?.Scheme_Name || "-"}
                    </td>
                    <td>{item?.Start_Date || "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="admin-table-footer">
        <div className="footer-buttons">
          <button
            onClick={() => {
              setConfig({
                ...config,
                skip: config?.skip - 1,
              });
            }}
          >
            Prev
          </button>
          <button
            onClick={() => {
              setConfig({
                ...config,
                skip: config?.skip + 1,
              });
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default MFManagement;
